<template>
  <span>
    <v-dialog v-model="dialogFinalizeRequest" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon  v-bind="attrs" v-on="on">mdi-flag-checkered</v-icon>
      </template>
      <v-card>
        <v-card-title>
          Zatvaranje zahteva
        </v-card-title>
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Iznos isplaćen kupcu" required outlined v-model="finalizeRequest.clientPayout" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
             <v-row>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-menu v-model="showDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      outlined
                      :rules="[rules.required]"
                      class="mt-0 pt-0"
                      label="Datum zatvaranja"
                      prepend-icon="mdi-calendar"
                      :value="formatDate(finalizeRequest.date)"
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="finalizeRequest.date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="finalizeRequest.date"
                    @input="showDateMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn color="primary" @click="sendOffer">
            Zatvori
            <v-icon right>mdi-flag-checkered</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import * as apiDocuments from '../../api/documents.js';
import Utils from '../../common/utils.js';

export default {
  name: "FinalizeRequestDialog",
  data: function () {
    return {
      dialogFinalizeRequest: false,

      finalizingRequestInProgress: false,
      finalizeRequest: {
        clientPayout: null,
        date: null
      },

      showDateMenu: false,

      snackbar: false,
      snackbarText: "",
      rules: {
        required: value => !!value || 'Obavezno polje',
      },
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    sendOffer() {
      if (this.offerPrice === null) {
        return;
      }

      this.finalizingRequestInProgress = true;

      apiDocuments.finalizeFactoringRequest(this.document.id, this.finalizeRequest, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.finalizingRequestInProgress = false;

          // zatvaranje modalnog prozora
          this.dialogFinalizeRequest = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Uspešno zatvoren zahtev za finansiranje";
          this.snackbar = true;

          this.$emit('updateDocumentsList');
        })
        .catch(() => {
          this.showError();
          this.finalizingRequestInProgress = false;
        });
    },
    onCancel () {
      this.dialogFinalizeRequest = false;
      this.offerPrice = null;
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    },
    formatDate(dateStr) {
      return Utils.formatDate(dateStr);
    }
  }
}
</script>
