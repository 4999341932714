<template>
  <span>
    <v-dialog v-model="dialogRegisterClientCompany" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="useIcon" v-bind="attrs" v-on="on">mdi-flag-checkered</v-icon>
        <v-btn v-else v-bind="attrs" v-on="on" class="primary">Registruj</v-btn>
      </template>
      <v-card>
        <v-card-title>
          Registracija kompanije
        </v-card-title>
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Šifra kompanije" required outlined v-model="registerCompanyRequest.externalReference"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn color="primary" @click="registerCompany">
            Registruj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import * as apiCompanies from '../../api/companies.js';

export default {
  name: "RegisterClientCompanyDialog",
  data: function () {
    return {
      dialogRegisterClientCompany: false,

      registrationInProgress: false,
      registerCompanyRequest: {
        externalReference: null,
        status: "Finalized"
      },

      snackbar: false,
      snackbarText: "",
      rules: {
        required: value => !!value || 'Obavezno polje',
      },
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    useIcon: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  methods: {
    registerCompany() {
      this.registrationInProgress = true;

      apiCompanies.changeClientConfig(this.company.id, this.registerCompanyRequest, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.registrationInProgress = false;

          // zatvaranje modalnog prozora
          this.dialogRegisterClientCompany = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Uspešno registrovana kompanija";
          this.snackbar = true;

          this.$emit('updateCompanyInfo');
        })
        .catch(() => {
          this.showError();
          this.registrationInProgress = false;
        });
    },
    onCancel () {
      this.dialogRegisterClientCompany = false;
      this.offerPrice = null;
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    }
  }
}
</script>
