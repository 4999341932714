var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onCancel.apply(null, arguments)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-flag-checkered")])]}}]),model:{value:(_vm.dialogFinalizeRequest),callback:function ($$v) {_vm.dialogFinalizeRequest=$$v},expression:"dialogFinalizeRequest"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Zatvaranje zahteva ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Iznos isplaćen kupcu","required":"","outlined":"","rules":[_vm.rules.required]},model:{value:(_vm.finalizeRequest.clientPayout),callback:function ($$v) {_vm.$set(_vm.finalizeRequest, "clientPayout", $$v)},expression:"finalizeRequest.clientPayout"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"clearable":"","outlined":"","rules":[_vm.rules.required],"label":"Datum zatvaranja","prepend-icon":"mdi-calendar","value":_vm.formatDate(_vm.finalizeRequest.date)},on:{"click:clear":function($event){_vm.finalizeRequest.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.showDateMenu = false}},model:{value:(_vm.finalizeRequest.date),callback:function ($$v) {_vm.$set(_vm.finalizeRequest, "date", $$v)},expression:"finalizeRequest.date"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error ma-2 white--text",attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v(" Odustani "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.sendOffer}},[_vm._v(" Zatvori "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-flag-checkered")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }