<template>
  <v-container fluid>
    <v-card elevation="4">
      <v-card-title>
        <v-row class="mt-2">
          <v-col cols="12" sm="2" class="py-0">
            <v-text-field
              v-model="filters.searchDocNo"
              append-icon="mdi-magnify"
              label="Broj dokumenta"
              single-line
              hide-details
              clearable
              outlined
              dense
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="py-0">
            <v-text-field
              v-model="filters.searchTidSender"
              append-icon="mdi-magnify"
              label="PIB dobavljača"
              single-line
              hide-details
              clearable
              outlined
              dense
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="2" class="py-0">
            <v-text-field
              v-model="filters.searchNameSender"
              append-icon="mdi-magnify"
              label="Naziv dobavljača"
              single-line
              hide-details
              clearable
              outlined
              dense
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="py-0">
            <v-text-field
              v-model="filters.searchTidReceiver"
              append-icon="mdi-magnify"
              label="PIB kupca"
              single-line
              hide-details
              clearable
              outlined
              dense
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="py-0">
            <v-text-field
              v-model="filters.searchNameReceiver"
              append-icon="mdi-magnify"
              label="Naziv kupca"
              single-line
              hide-details
              clearable
              outlined
              dense
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
          <v-col sm="2" class="py-0">
             <v-select
              dense
              :items="documentStatuses"
              v-model="filters.status"
              item-text="text"
              item-value="value"
              label="Status dokumenta"
              outlined
              clearable>
            </v-select>
          </v-col>
          <v-col>
            <v-btn @click="getDocumentsFromAPI" class="primary">
              Filtriraj
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
      :headers="headers"
      :items="listOfDocuments"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      >
        <template v-slot:[`item.issueDate`]="{ item }">
          <span>{{ format_date(item.issueDate) }}</span>
        </template>

        <template v-slot:[`item.dueDate`]="{ item }">
          <span>{{ format_date(item.dueDate) }}</span>
        </template>

        <template v-slot:[`item.platypusStatus`]="{ item }">
          <span :class="mapStatusToColor(item.platypusStatus)">{{ mapStatusEngToSrb(item.platypusStatus) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="getPdfs(item.id)">
            mdi-file-pdf-box
          </v-icon>

          <send-offer-dialog v-if="item.platypusStatus === 'New'" :document="item"  @updateDocumentsList="getDocumentsFromAPI" />

          <reject-to-give-offer-dialog v-if="item.platypusStatus === 'New'" :document="item"  @updateDocumentsList="getDocumentsFromAPI" />

          <finalize-request-dialog
            v-if="item.platypusStatus === 'OfferAccepted' || item.platypusStatus === 'InProgress'"
            :document="item"
            @updateDocumentsList="getDocumentsFromAPI" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import moment from 'moment';

import SendOfferDialog from './dialogs/SendOfferDialog.vue';
import FinalizeRequestDialog from './dialogs/FinalizeRequestDialog.vue';
import RejectToGiveOfferDialog from './dialogs/RejectToGiveOfferDialog.vue';

export default {
  name: "DocumentsList",
  components: {
    SendOfferDialog,
    FinalizeRequestDialog,
    RejectToGiveOfferDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      filters: {
        companyId: this.companyId,
        status: null,
        searchDocNo: null,
        searchTidSender: null,
        searchTidReceiver: null,
        searchNameSender: null,
        searchNameReceiver: null
      },

      listOptions: {
        sortDesc: [true]
      },

      listOfDocuments: [],

      headers: [
          { text: 'ID', value: 'id', width: "3%", sortable: false  },
          { text: 'Broj dokumenta', value: 'docNo', width: "8%", sortable: false  },
          { text: 'Iznos', value: 'totalAmount', width: "5%", sortable: false  },
          { text: 'Datum dokumenta', value: 'issueDate', width: "8%", sortable: false  },
          { text: 'Datum valute', value: 'dueDate', width: "8%", sortable: false  },
          { text: 'Dobavljač', value: 'nameSender', width: "15%", sortable: false },
          { text: 'PIB dobavljača', value: 'tidSender', width: "8%", sortable: false },
          { text: 'Kupac', value: 'nameReceiver', width: "15%", sortable: false },
          { text: 'PIB kupca', value: 'tidReceiver', width: "8%", sortable: false },
          { text: 'Status', value: 'platypusStatus', width: "7%", sortable: false },
          { text: 'SEF status', value: 'sefStatus', width: "7%", sortable: false },
          { text: 'Akcije', value: 'actions', width:"8%", sortable: false}
      ],

      documentStatuses: [
        { value: 'New', text: "Novi" },
        { value: 'DocumentRejected', text: "Odbijen zahtev" },
        { value: 'Offered', text: "Ponuda" },
        { value: 'OfferAccepted', text: "Prihvaćena ponuda" },
        { value: 'OfferRejected', text: "Odbijena ponuda" },
        { value: 'InProgress', text: "U toku" },
        { value: 'Finalized', text: "Završen" },
        { value: 'PendingRegistration', text: "Registracija u toku"}
      ],
    }
  },
  props: {
    advancedSearch: {
      type: Object,
      required: false
    },
    companyId: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    options: {
      handler () {
        this.copyListOptions();
        //this.options.search = this.queryString;
        this.getDocumentsFromAPI()
      },
      deep: true,
    },
  },
  mounted () {
    //this.getDocumentsFromAPI()
  },
  methods: {
    getDocumentsFromAPI() {
      this.loading = true;

      documentsAPI.getList(this.listOptions, this.filters, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;

          this.listOfDocuments = responseData;
        })
        .catch(() => {
          this.listOfDocuments = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.page = 1;
      this.copyListOptions();
      this.getDocumentsFromAPI();
    },

    onClearSearch() {
      this.options.page = 1;
      this.queryString = '';
      this.copyListOptions();
      this.getDocumentsFromAPI()
    },

    onRowClick(document) {
      let route = this.$router.resolve({ path: '/documents/' + document.id });
      window.open(route.href, '_blank');
    },

    mapStatusEngToSrb(status) {
      return this.documentStatuses.filter(s => s.value == status).map(s => s.text)[0];
    },

    mapStatusToColor(status) {
      switch (status) {
        case "New": return "blue--text";
        case "DocumentRejected": return "orange--text";
        case "Offered": return "lime--text text--darken-2";
        case "OfferAccepted": return "green--text";
        case "OfferRejected": return "red--text";
        case "InProgress": return "grey--text text--darken-4";
        case "Finalized": return "green--text text--darken-3";
        case "PendingRegistration": return "grey--text";
        default: return "black--text";
      }
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    performAdvancedSearch() {
      // this.options.search = this.queryString;
      // this.options.page = 1;
      // this.options.documentSearchType = this.documentSearchType;
      this.options.page = 1;
      this.copyListOptions();
      this.getDocumentsFromAPI();
    },

    copyListOptions() {
      this.listOptions.pageNum = this.options.page;
      this.listOptions.pageSize = this.options.itemsPerPage;
      this.listOptions.sortBy = this.options.sortBy;
      this.listOptions.sortDesc = this.options.sortDesc;
      this.listOptions.query = this.queryString;
      this.listOptions.documentSearchType = this.documentSearchType;
    },

    getPdfs(documentId) {
      documentsAPI.getFiles(documentId, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response.json())
        .then(responseData => {
          this.openFilesInTabs(responseData);
        })
        .catch(() => {
        })
    },

    openFilesInTabs(filesArray) {
      filesArray.forEach((fileData, index) => {
      // Decode base64 data
      const byteCharacters = atob(fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create Blob from decoded data
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create object URL from Blob
      const url = URL.createObjectURL(blob);

      // Open new tab with PDF
      const newTab = window.open(url, `_blank`);
      if (newTab) {
        newTab.document.title = `File ${index + 1}`;
      } else {
        console.error(`Failed to open file ${index + 1}`);
      }
    });
}

  }
}
</script>

<style scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>
