<template>
  <div>
    <v-card>
      <v-card-title>
        Kompanije
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="end">
          <v-col cols="12" sm="2" class="py-0">
            <v-select
              dense
              :items="onboardingStatuses"
              v-model="filterOnboardingStatus"
              item-text="text"
              item-value="value"
              label="Status registracije"
              outlined
              clearable>
            </v-select>
          </v-col>
          <v-col cols="12" sm="3" class="py-0">
            <v-text-field
              v-model="nameSearchQuery"
              append-icon="mdi-magnify"
              label="Pretraga naziva"
              single-line
              outlined
              dense
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearNameSearch()'
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" class="py-0">
            <v-text-field
              v-model="tidSearchQuery"
              append-icon="mdi-magnify"
              label="Pretraga PIBa"
              single-line
              outlined
              dense
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearTidSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfCompanies"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import * as apiCompanies from '../api/companies.js'
export default {
  name: "CompaniesList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      nameSearchQuery: null,
      tidSearchQuery: null,
      listOfCompanies: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%" },
          { text: 'Naziv', value: 'name', width: "15%" },
          { text: 'PIB', value: 'tid', width: "10%" },
          { text: 'MB', value: 'mn', width: "10%" },
          { text: 'Adresa', value: 'address', width: "15%" },
          { text: 'Grad', value: 'town', width: "20%" },
          { text: 'Status', value: 'onboardingStatus', width: "10%" },
      ],
      onboardingStatuses: [
        { value: 'Requested', text: "Zahtev" },
        { value: 'Accepted', text: "Odobren" },
        { value: 'Rejected', text: "Odbijen" },
        { value: 'Finalized', text: "Završen" },
      ],
      filterOnboardingStatus: null
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCompaniesFromAPI()
      },
      deep: true,
    },
    filterOnboardingStatus: {
      handler() {
        this.getCompaniesFromAPI();
      }
    }
  },
  mounted () {
    this.getCompaniesFromAPI()
  },
  methods: {
    getCompaniesFromAPI() {
      this.loading = true;

      let filters = {
        onboardingStatus: this.filterOnboardingStatus,
        nameSearchQuery: this.nameSearchQuery,
        tidSearchQuery: this.tidSearchQuery
      };

      apiCompanies.getList(this.options, filters, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.listOfCompanies = responseData
        })
        .catch(() => {
          this.listOfCompanies = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.page = 1;
      this.getCompaniesFromAPI();
    },

    onClearNameSearch() {
      this.nameSearchQuery = "";
      this.getCompaniesFromAPI();
    },

      onClearTidSearch() {
      this.tidSearchQuery = "";
      this.getCompaniesFromAPI();
    },

    onRowClick(company) {
      let route = this.$router.resolve({ path: '/companies/' + company.id });
      window.open(route.href, '_blank');
    },
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
