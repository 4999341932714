<template>
  <span>
    <v-dialog v-model="dialogSendOffer" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon  v-bind="attrs" v-on="on" >mdi-offer</v-icon>
      </template>
      <v-card>
        <v-form fast-fail @submit.prevent>
          <v-card-title>
            Slanje ponude
          </v-card-title>
          <v-card-text class="mt-4">
            <v-row>
              <v-col sm="6">

                <v-row>
                  <v-col cols="12" sm="12" class="pt-0 pb-0">
                    <v-text-field label="Procenat finansiranja" required outlined v-model="newOffer.financePercent" :rules="[rules.required, rules.numberOnly, rules.decimalSeparator]">
                      <template v-slot:append>
                        <v-icon>mdi-percent</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" class="pt-0 pb-0">
                    <v-text-field label="Mesečna kamata" required outlined v-model="newOffer.monthlyInterest" :rules="[rules.required, rules.numberOnly, rules.decimalSeparator]">
                      <template v-slot:append>
                        <v-icon>mdi-percent</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" class="pt-0 pb-0">
                    <v-text-field label="Naknada za obradu" required outlined v-model="newOffer.processingFeesPercent" :rules="[rules.numberOnly, rules.decimalSeparator]">
                      <template v-slot:append>
                        <v-icon>mdi-percent</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="6">
                <v-list dense>
                  <v-list-item v-for="item in documentProperties" :key="item.label">
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                      <v-list-item-subtitle>{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error ma-2 white--text" text @click="onCancel">
              Odustani
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn color="primary" @click="sendOffer" type="submit">
              Pošalji
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import * as apiDocuments from '../../api/documents.js';
import Utils from '../../common/utils.js';

export default {
  name: "SendOfferDialog",
  data: function () {
    return {
      dialogSendOffer: false,

      creatingOfferInProgress: false,
      newOffer: {
        financePercent: null,
        monthlyInterest: null,
        processingFeesPercent: null
      },

      snackbar: false,
      snackbarText: "",
      rules: {
        required: value => !!value || "Obavezno polje",
        numberOnly: value => (value === null || value === '' || (value !== null && value.match(/^\d*(\.\d*)?$/))) || "Nevalidan unos. Dozvoljen je samo broj",
        decimalSeparator: value => (value === null || value === '' || (value !== null && !value.includes(","))) || "Koristiti tačku kao decimalni separator"
      },

      documentProperties: [
        { label: "PIB dobavljača", propertyName: 'tidSender', icon: "mdi-fingerprint" },
        { label: "Naziv dobavljača", propertyName: 'nameSender', icon: "mdi-domain" },
        { label: "PIB kupca", propertyName: 'tidReceiver', icon: "mdi-fingerprint" },
        { label: "Naziv kupca", propertyName: 'nameReceiver', icon: "mdi-domain" },
        { label: "Broj dokumenta", propertyName: 'docNo', icon: "mdi-invoice" },
        { label: "Datum izdavanja", propertyName: 'issueDate', icon: "mdi-calendar-range" },
        { label: "Datum valute", propertyName: 'dueDate', icon: "mdi-calendar-range" },
        { label: "Ukupni iznos", propertyName: 'totalAmount', icon: "mdi-cash" },
        { label: "Iznos za plaćanje", propertyName: 'payableAmount', icon: "mdi-cash" },
      ],
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    sendOffer() {
      if (this.newOffer.financePercent === null) {
        return;
      }

      if (this.newOffer.monthlyInterest === null) {
        return;
      }

      if (this.newOffer.processingFeesPercent === null) {
        return;
      }

      this.creatingOfferInProgress = true;

      apiDocuments.createOffer(this.document.id, this.newOffer, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.creatingOfferInProgress = false;

          // zatvaranje modalnog prozora
          this.dialogSendOffer = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Uspešno poslata ponuda";
          this.snackbar = true;

          this.$emit('updateDocumentsList');
        })
        .catch(() => {
          this.showError();
          this.creatingOfferInProgress = false;
        });
    },
    onCancel () {
      this.dialogSendOffer = false;
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    },
    getPropertyValue(propertyName) {
      let dateProperties = [ "issueDate", "dueDate" ];
      if (dateProperties.includes(propertyName)) {
        return Utils.formatDate(this.document[propertyName]);
      }

      return this.document[propertyName];
    },
  }
}
</script>
