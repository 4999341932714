import * as fetch from './fetch'

export function getDocument(id, token) {
  const endpoint = '/documents/' + id
  return fetch.GET(endpoint, token, true)
}

export function getList(options, filtersParams, token, factoringCompanyId) {
  let parameters = mapToParameters(options, filtersParams);

  const endpoint = '/documents'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true, factoringCompanyId)
}

export function createOffer(documentId, body, token, factoringCompanyId) {
  const endpoint = `/documents/${documentId}/offer`;
  return fetch.POST(endpoint, token, body, true, factoringCompanyId);
}

export function finalizeFactoringRequest(documentId, body, token, factoringCompanyId) {
  const endpoint = `/documents/${documentId}/finalize`;
  return fetch.POST(endpoint, token, body, true, factoringCompanyId);
}

export function rejectRequest(documentId, token, factoringCompanyId) {
  const endpoint = `/documents/${documentId}/reject`;
  return fetch.POST(endpoint, token, null, true, factoringCompanyId);
}

export function postResendDocumentToSefLink(documentId, token) {
  const endpoint = '/documents/resendDocumentToEdex/' + documentId;
  return fetch.POST(endpoint, token, null, true);
}

export function getFiles(documentId, token, factoringCompanyId) {
  const endpoint = `/documents/${documentId}/files`
  return fetch.GET(endpoint, token, true, factoringCompanyId)
}

function mapToParameters(options, filters) {
  let parameters = {}

  if (options.pageNum !== null) {
    parameters.page = options.pageNum - 1
  }

  if (options.pageSize !== null) {
    parameters.size = options.pageSize
  }

  if (filters.searchDocNo) {
    parameters.docNo = filters.searchDocNo
  }

  if (filters.searchTidSender) {
    parameters.tidSender = filters.searchTidSender
  }

  if (filters.searchTidReceiver) {
    parameters.tidReceiver = filters.searchTidReceiver
  }

  if (filters.searchNameSender) {
    parameters.nameSender = filters.searchNameSender
  }

  if (filters.searchNameReceiver) {
    parameters.nameReceiver = filters.searchNameReceiver
  }

  if (filters.companyId) {
    parameters.companyId = filters.companyId;
  }

  if (filters.status) {
    parameters.status = filters.status;
  }

  return parameters
}
