<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <document-details :document="document" v-if="document !== null"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiDocuments from '../api/documents.js'
import DocumentDetails from '../components/DocumentDetails'

export default {
  name: "DocumentDetailsPage",
  components: {
    DocumentDetails
  },
  data: function () {
    return {
      document: null,
      loading: false
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getDocument(id);
  },
  methods: {
    getDocument(id) {
      this.loading = true;

      apiDocuments.getDocument(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.document = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.document = null;
          this.loading = false;
        })
    }
  }
}
</script>
