import * as fetch from './fetch'

export function logIn(username, password) {
  const requestBody = {
    username: username,
    password: password
  }

  return fetch.POST("/auth/login", null, JSON.stringify(requestBody), false)
}
