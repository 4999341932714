<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      {{ getCompanyProperty('name') }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in companyInformationPart1" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in companyInformationPart2" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <register-client-company-dialog v-if="company.onboardingStatus === 'Requested' || company.onboardingStatus === 'None'" :company="company" :useIcon="false" />
    </v-card-actions>
  </v-card>
</template>

<script>
import RegisterClientCompanyDialog from './dialogs/RegisterClientCompanyDialog.vue';

export default {
  name: 'CompanyDetails',
  components: {
    RegisterClientCompanyDialog
  },
  data: function () {
    return {
      loading: false,
      companyInformationPart1: [
        { label: "PIB", propertyName: 'tid', icon: "mdi-fingerprint" },
        { label: "MB", propertyName: 'mn', icon: "mdi-fingerprint" },
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "Država", propertyName: 'country', icon: "mdi-flag" }
      ],
      companyInformationPart2: [
        { label: "Telefon", propertyName: 'phone', icon: "mdi-phone" },
        { label: "Email", propertyName: 'email', icon: "mdi-email" },
        { label: "Status registracije", propertyName: 'onboardingStatus', icon: "mdi-registered-trademark" },
      ]
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCompanyProperty(propertyName) {
      return this.company[propertyName];
    },
    updateParentComponent (companyId) {
      this.$emit('updateCompany', companyId);
    }
  }
}
</script>
