import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import CompaniesPage from '../views/CompaniesPage.vue'
import CompanyDetailsPage from '../views/CompanyDetailsPage.vue'

import DocumentsPage from '../views/DocumentsPage.vue'
import DocumentDetailsPage from '../views/DocumentDetailsPage.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/companies',
    name: 'Companies',
    component: CompaniesPage
  },
  {
    path: '/companies/:id',
    name: 'CompanyDetailsPage',
    component: CompanyDetailsPage
  },
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsPage
  },
  {
    path: '/documents/:id',
    name: 'DocumentDetailsPage',
    component: DocumentDetailsPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isauth = store.getters.isLoggedIn
  if (to.name !== 'Login' && isauth === false) {
    if (from.name === 'Login') {
      next(false)
    }
    next({ path: '/login' })
  }
  else {
    next()
  }
})

export default router
