<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="5">
        <!-- <img width="400px" src="../assets/altermedia-logo.png"> -->
        <v-img :src="seflinkLogo" >
        </v-img>
        <div class="text-center">
          Copyright &copy; 2024
        </div>
      </v-col>
    </v-row>
    <v-row align="bottom" justify="center">
      <small>
        verzija {{ appVersion }}
      </small>
    </v-row>
</v-container>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      seflinkLogo: require('../assets/SefLinkLogo.svg'),
      appVersion: process.env.VUE_APP_VERSION,
    }
  }
}
</script>
