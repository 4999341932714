<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col sm="9">
        <companies-list />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompaniesList from '../components/CompaniesList.vue'

export default {
  name: "CompaniesPage",
  components: {
    CompaniesList,
  }
}
</script>
