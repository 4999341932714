import * as fetch from './fetch'

export function getCompany(id, token, factoringCompanyId) {
  const endpoint = '/companies/' + id
  return fetch.GET(endpoint, token, true, factoringCompanyId)
}

export function getList(options, filters, token, factoringCompanyId) {
  let parameters = mapToParameters(options, filters)

  const endpoint = '/companies'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true, factoringCompanyId)
}

export function changeClientConfig(companyId, body, token, factoringCompanyId) {
  const endpoint = '/companies/' + companyId;
  return fetch.PATCH(endpoint, token, body, true,  factoringCompanyId)
}

function mapToParameters(options, filters) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (filters.onboardingStatus) {
    parameters.status = filters.onboardingStatus
  }

  if (filters.nameSearchQuery) {
    parameters.name = filters.nameSearchQuery
  }

  if (filters.tidSearchQuery) {
    parameters.tid = filters.tidSearchQuery
  }

  return parameters
}
