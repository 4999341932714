<template>
  <span>
    <v-dialog v-model="dialogRejectToGiveOffer" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon  v-bind="attrs" v-on="on">mdi-cancel</v-icon>
      </template>
      <v-card>
        <v-card-title>
          Odbijanje zahteva
        </v-card-title>
        <v-card-text class="mt-4">
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn color="primary" @click="rejectRequest">
            Odbij
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </span>
</template>

<script>
import * as apiDocuments from '../../api/documents.js';

export default {
  name: "RejectToGiveOfferDialog",
  data: function () {
    return {
      dialogRejectToGiveOffer: false,
      rejectingRequestInProgress: false,
      snackbar: false,
      snackbarText: "",
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    rejectRequest() {
      this.rejectingRequestInProgress = true;

      apiDocuments.rejectRequest(this.document.id, this.$store.getters.token, this.$store.getters.factoringCompanyId)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.rejectingRequestInProgress = false;

          // zatvaranje modalnog prozora
          this.dialogRejectToGiveOffer = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Uspešno odbijen zahtev za ponudom";
          this.snackbar = true;

          this.$emit('updateDocumentsList');
        })
        .catch(() => {
          this.showError();
          this.rejectingRequestInProgress = false;
        });
    },
    onCancel () {
      this.dialogRejectToGiveOffer = false;
      this.offerPrice = null;
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    },
  }
}
</script>
