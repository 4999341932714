import store from '../store'
import router from '../router'

const apiBaseUrl = process.env.VUE_APP_API_BASEURL

export function GET (url, token, authorize = true, factoringCompanyId = null) {
  const requestHeaders = new Headers();

  const requestOptions = {
    method: 'GET'
  }

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  if (factoringCompanyId !== null) {
    requestHeaders.set('FactoringCompanyId', factoringCompanyId);
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      checkResponse(response);
      if (response.ok) {
        return response
      } else {
        throw response
      }
    })
    .catch(e => {
      checkResponse(e);
      throw e;
    })
}

export function POST (url, token, body = undefined, authorize = true, factoringCompanyId = null) {
  const requestHeaders = new Headers();

  if (factoringCompanyId !== null) {
    requestHeaders.set('FactoringCompanyId', factoringCompanyId);
  }

  const requestOptions = {
    method: 'POST'
  }

  if (body) {
    let bodyString = ''
    if (typeof body !== 'string') {
      bodyString = JSON.stringify(body)
    } else {
      bodyString = body
    }

    requestOptions.body = bodyString
    requestHeaders.set('Content-Type', 'application/json')
  }

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      }
      else {
        checkResponse(response);
        if (response.status === 400) {
          return response.json()
            .then(jsonData => {
              if (jsonData.errorCode)
                throw new Error(jsonData.errorCode);
              else
                throw new Error(jsonData.error); // ovo je error message
            });
        }
        else {
          throw response;
        }
      }
    })
}

export function PUT (url, token, body = undefined, authorize = true) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'PUT'
  }

  if (body) {
    let bodyString = ''
    if (typeof body !== 'string') {
      bodyString = JSON.stringify(body)
    } else {
      bodyString = body
    }

    requestOptions.body = bodyString
    requestHeaders.set('Content-Type', 'application/json')
  }

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      } else {
        checkResponse(response);
        throw response
      }
    })
}

export function PATCH (url, token, body = undefined, authorize = true, factoringCompanyId = null) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'PATCH'
  }

  if (body) {
    let bodyString = ''
    if (typeof body !== 'string') {
      bodyString = JSON.stringify(body)
    } else {
      bodyString = body
    }

    requestOptions.body = bodyString
    requestHeaders.set('Content-Type', 'application/json')
  }

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  if (factoringCompanyId !== null) {
    requestHeaders.set('FactoringCompanyId', factoringCompanyId);
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      } else {
        checkResponse(response);
        throw response
      }
    })
}

export function DELETE(url, token, authorize = true) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'DELETE'
  }

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      }
      else {
        checkResponse(response);
        throw response
      }
    })
}

function checkResponse(x) {
  if (x['status'] === 401) {
    store.commit('token', null);
    router.push('/');
  }
}

export function POSTMultipart(url, token, body = undefined, file, authorize, requestPartName) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'POST'
  }

  let bodyString = '';
  if (body) {
    if (typeof body !== 'string') {
      bodyString = JSON.stringify(body)
    } else {
      bodyString = body
    }
  }

  const formData  = new FormData();
  formData.append("excelFile", file, file.name);
  formData.append(requestPartName, bodyString);
  requestOptions.body = formData;

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      }
      else {
        checkResponse(response);
        if (response.status === 400) {
          return response.json()
            .then(jsonData => {
              if (jsonData.errorCode)
                throw new Error(jsonData.errorCode);
              else
                throw new Error(jsonData.error); // ovo je error message
            });
        }
        else {
          throw response;
        }
      }
    })
}

export function PUTMultipart(url, token, body = undefined, file, authorize, requestPartName) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'PUT'
  }

  let bodyString = '';
  if (body) {
    if (typeof body !== 'string') {
      bodyString = JSON.stringify(body)
    } else {
      bodyString = body
    }
  }

  const formData  = new FormData();
  formData.append("excelFile", file, file.name);
  formData.append(requestPartName, bodyString);
  requestOptions.body = formData;

  if (authorize) {
    requestHeaders.set('Authorization', 'Bearer ' + token)
  }

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch(apiBaseUrl + url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      }
      else {
        checkResponse(response);
        if (response.status === 400) {
          return response.json()
            .then(jsonData => {
              if (jsonData.errorCode)
                throw new Error(jsonData.errorCode);
              else
                throw new Error(jsonData.error); // ovo je error message
            });
        }
        else {
          throw response;
        }
      }
    })
}
